<!-- START DESKTOP -->
<div *ngIf="!isMobile" class="page-grid w-100 h-100 o-y-hide">
  <!-- None -->
  <div class="border-right" (click)="contactSplash.hide()"></div>

  <!-- Menu -->
  <div class="content-grid w-100 h-100">

    <header class="header-grid w-100 h-100">
      <div class="h-100 menu-item-grid border-bottom">
        <div></div>
        <nav class="menu-items">
          <a class="menulinks" routerLink="about">ABOUT</a>
          <a class="menulinks" routerLink="works">WORKS</a>
          <!-- <a class="menulinks" routerLink="quote">QUOTE</a> -->
          <a class="clicky menulinks" (click)="contactSplash.show()">CONTACT</a>
        </nav>
      </div>
      <div class="heading">
        <h1 class="clicky" routerLink="home">Keiken Studio.</h1>
      </div>
    </header>
  </div>

  <!-- Social Pannel -->
  <div class="social-grid border-right h-100 w-100" (click)="contactSplash.hide()">
    <a class="ig-icon" href="https://www.instagram.com/keiken.studio/" target="_blank">
      ig
    </a>
    <a class="fb-icon" href="https://www.facebook.com/Keiken-Studio-102687111574633/?modal=admin_todo_tour"
      target="_blank">
      fb
    </a>
    <!-- <a class="g-icon" href="https://www.google.com.au/search?hl=en&_ga=2.26000184.447703007.1606605899-258473660.1601967583&q=Keiken%20Studio&ludocid=10431451014136223910&lsig=AB86z5XmDqVc9J5yR193V05vXyQq&fbclid=IwAR2W6oJOuWvF_MBMgKO8loLxvHwm8nHugNVY7ZLq4TAWyOCSb3TeeFkWr_M" target="_blank">
      g+
    </a> -->

    <!-- <img class="chatbot mobile" src="chat-bot-icon.png"> -->
  </div>

  <!-- Content -->
  <section class="background-grid content-scroll" (click)="contactSplash.hide()">
    <router-outlet></router-outlet>
  </section>
</div>
<!-- END DESKTOP -->


<img *ngIf="!isMobile" (click)="contactSplash.show()" class="floating chatbox-icon clicky"
  src="../assets/ChatBotIcon.png" alt="Contact Keiken Icon">
<app-welcome></app-welcome>
<app-contact></app-contact>


<!-- MOBILE -->
<div class="h-100" *ngIf="isMobile">
  <div class="content-pane">
    <div>
      <!-- HEADING -->
      <div class="m-y-h flex align-end justify-center">
        <h1 routerLink="home">Keiken Studio.</h1>
      </div>

      <!-- NAV -->
      <nav class="p-b-h flex justify-space-evenly align-center border-bottom">
        <a routerLink="about">ABOUT</a>
        <a routerLink="works">WORKS</a>
        <!-- <a routerLink="quote">QUOTE</a> -->
        <a class="clicky" (click)="contactSplash.show()">CONTACT</a>
      </nav>
    </div>

    <!-- CONTENT  -->
    <div style="height:0; overflow-y: scroll; flex:1;" class="background-grid">
      <router-outlet></router-outlet>
    </div>

    <!-- FOOTER -->
    <div class="socials border-top flex align-center p-l-1h">
      <a class="m-r-1h" href="https://www.instagram.com/keiken.studio/" target="_blank">
        ig
      </a>
      <a class="m-r-1h" href="https://www.facebook.com/Keiken-Studio-102687111574633/?modal=admin_todo_tour"
        target="_blank">
        fb
      </a>
      <div class="chatbox-container p-r-1h">
        <img (click)="contactSplash.show()" class="chatbox-icon" src="../assets/ChatBotIcon.png" alt="Chatbox icon" />
      </div>
    </div>
  </div>
</div>
<!-- END MOBILE -->