<!-- DESKTOP -->

<div class="content-container desktop">
  <div class="flex align-center justify-center">
    <img width="100%" src="../../../assets/MacBook-Pro-lg.gif" alt="Keiken Qualities Gif" />
  </div>
  
  <div class="content-area">
    <h2>WELCOME</h2>
    <div class="m-t-1h content-wrapper">
      <p>Welcome to Keiken, a new-generation studio designed for; <br/> entrepreneurs, sole traders & startups.</p>
      <p>The Keiken experience integrates the best of design and <br/> technology with a grassroots understanding of what it <br/>means to be small business.</p>
      <p>Tight turn around times, transparent prices and a method <br/>of proven success sets Keiken apart.</p>
      <p>More than a creative agency we offer a unique partnership <br/>of full transparency & communication, putting your small <br/>business in control.</p>
      <button routerLink="/about" class="m-t-1h">LEARN MORE</button>
    </div>
  </div>
</div>

<!-- END DESKTOP -->


<!-- --- MOBILE --- -->
<div class="content-container mobile">
  <div>
    <h2>SERVICES</h2>
  </div>
  
  <div class="p-l-1h m-b-1">
    <app-img-w-heading class="m-b-1" *ngFor="let s of services" [imgPath]="s.imgPath" [imgAlt]="s.imgAlt" [heading]="s.heading" [imgClass]="s.imgClass"></app-img-w-heading>
  </div>

  <div class="p-l-1h p-r-1h">
    <p>The Keiken experience integrates the best of design and technology with a grassroots understanding of what it means to be small business.</p>
    <p>Tight turn around times, transparent prices and a method of proven success sets Keiken apart.</p>
    <p>More than a creative agency we offer a unique partnership of full transparency.</p>
    <button class="m-y-1h" routerLink="/about">LEARN MORE</button>
  </div>
</div>
<!-- --- MOBILE END --- -->