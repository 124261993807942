
<!-- <div class="flex align-center m-b-1">
  <img class="icon" [src]="imgPath" [alt]="imgAlt">
  <h4>{{ heading }}</h4>
  <img src="../../../assets/" alt="">
</div> -->

<div class="img-grid  m-b-1">

  <div class="flex align-center justify-center">
    <img [ngClass]="imgClass ? imgClass : ''" class="icon" [src]="imgPath" [alt]="imgAlt">
  </div>

  <div class="flex align-center justify-start">
    <h4>{{ heading }}</h4>
  </div>

</div>