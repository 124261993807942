import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'k-package-selection',
  templateUrl: './package-selection.component.html',
  styleUrls: ['./package-selection.component.css']
})
export class PackageSelectionComponent implements OnInit {
  @Input() heading: string;
  @Input() options: any = null;

  optionSelected : FormControl = new FormControl(true)

  constructor() { }

  ngOnInit(): void {
  }

}
