import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WelcomeSplashService {

  private displayWelcome : boolean = false;

  constructor() { }

  public show = () : void => {
    this.displayWelcome = true;
  }
  public hide = () : void => {
    this.displayWelcome = false;
  }
  public display = () : boolean => {
    return this.displayWelcome;
  }
}
