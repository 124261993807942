import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ContactSplashService } from 'src/app/services/contact-splash.service';

interface CarouselInfo {
  imgSrc: string,
  title: string,
  description: string[],
  thumbnailSrc: string,
  quoteText: string,
  quoteWriter: string,
  class?: string,
}

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),

      transition('void => *', [
        animate(600)
      ]),

      transition('in <=> out', [
        animate(600)
      ]),
    ])
  ]
})
export class WorksComponent implements OnInit {

  carouselData: CarouselInfo[] = [
    {
      imgSrc: '../../../assets/works/Petals&Pieces/mainImg.png',
      title: 'Petals & Pieces Floristry',
      description: [
        'Petals & Pieces is inspired by the rustic beauty of the natural elements, utilising floral art techniques to refine create organic beauty.',
        'The logo and packaging design captures the organic floral aesthetic of the business to create a playful brand.'
      ],
      thumbnailSrc: '../../../assets/works/Petals&Pieces/thumbnail.png',
      quoteText: '"It was great working with Keiken Studio to bring my new business to life!"',
      quoteWriter: 'Esta'
    },
    {
      imgSrc: '../../../assets/works/GutterPro/mainimage.png',
      title: 'Gutter Pro Coffs Harbour',
      description: [
        'GutterPro provides professional guttering services for the Mid North Coast Region. The refreshed GutterPro website expresses a connection to the local area underpinned by Phil’s passion for quality craftsmanship.',
      ],
      thumbnailSrc: '../../../assets/works/GutterPro/thumbnail.png',
      quoteText: '"It was great working with Keiken Studio to transform my existing website into something new and fresh."',
      quoteWriter: 'Phil',
      class: 'w-100',
    },
    {
      imgSrc: '../../../assets/works/LaurenSarahPhotography/mainimage.png',
      title: 'Lauren Sarah Photography',
      description: [
        'Branding and collateral design that expresses quality and passion for craftsmanship.', 
        'This minimalistic design visually supports the captivating photography of Lauren Sarah Photography.'
      ],
      thumbnailSrc: '../../../assets/works/LaurenSarahPhotography/thumbnail.png',
      quoteText: '"I absolutely loved working with Keiken Studio to create a brand that perfectly represents my business."',
      quoteWriter: 'Lauren'
    },
    // {
    //   imgSrc: '../../../assets/works/BlueBean/mainimage.png',
    //   title: 'Blue Bean Coffee Roasters',
    //   description: [ 'Created to share a passion for botique coffee, Blue Bean Coffee Roasters are ethical and local in every sense. The bright & simplistic packaging design reflectd Blue Bean’s no fuss aproach to delivering simple high quality blends.' ],
    //   thumbnailSrc: '../../../assets/works/BlueBean/thumbnail.png',
    //   quoteText: '“Keiken Studio really refreshed our look and helped us reach more clients to grow our business.”',
    //   quoteWriter: 'Blue Bean Coffee',
    // }
  ]

  selectedCarousel: CarouselInfo
  selectedIndex: number = 0

  animationState: 'in' | 'out' = 'in'
  outTimer = null
  changeLength: number = 8000

  constructor(
    public contactSplash: ContactSplashService,
  ) { }

  ngOnInit(): void {
    this.selectedChange(this.selectedIndex)

    this.outTimer = setInterval(this.outAnimation, this.changeLength - 600)
    setInterval(this.carouselChange, this.changeLength)
  }

  selectedChange = (index): void => {
    this.selectedIndex = index
    this.selectedCarousel = this.carouselData[this.selectedIndex]
  }

  carouselChange = (): void => {
    this.selectedIndex++
    if (this.selectedIndex >= this.carouselData.length) {
      this.selectedChange(0)
    } else {
      this.selectedChange(this.selectedIndex)
    }

    this.animationState = 'in'
    this.outTimer = setInterval(this.outAnimation, this.changeLength - 600)
  }

  outAnimation = (): void => {
    this.animationState = 'out'
    clearInterval(this.outTimer)
  }

}
