

<div>
  <h3>STRATEGY</h3>
  <p>Stratergy is the game plan of any business.</p>
  <p>Its your passion & quality that makes your business thrive, a stratergy takes your big ideas and connects them with your audience. With a strong and considered strategy you’ll be ready to succeed and grow.</p>


  <k-package-selection *ngFor="let o of options" [heading]="o.title" [options]="o.options"></k-package-selection>
  <!-- <k-i-toggle></k-i-toggle>
  <k-i-checkbox title="45 Minute Virtual Coffee"></k-i-checkbox>
  <k-i-checkbox title="Business Strategy Proposal"></k-i-checkbox>
  <k-i-checkbox title="Research & Analytics Report"></k-i-checkbox>
  <k-i-checkbox title="12 Week Business Strategy Plan"></k-i-checkbox>
  <k-i-checkbox title="Google Plus Account Setup"></k-i-checkbox>
  <k-i-checkbox title="Facebook Set Up + Content Kick Start"></k-i-checkbox>
  <k-i-checkbox title="Instagram Set Up + Content Kick Start"></k-i-checkbox>
  <k-i-checkbox title="LinkedIn Account Set Up"></k-i-checkbox> -->
</div> 
