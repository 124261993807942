

<div class="overlay o-y-hide" [ngStyle]="!welcomeSplash.display() ? { 'display': 'none' } : {}">

  <div class="content-wrapper">
    <h2>Welcome to</h2>
    <h1 class="m-b-h">Keiken Studio.</h1>
    <p>A new generation studio <br class="break" />designed for; entrepreneurs, <br class="break" />sole traders & startups.</p>

    <img class="m-y-1h continue-img" src="../../../assets/welcome-arrow.png" alt="Welcome arrow" (click)="navToHome()">
  </div>

</div>