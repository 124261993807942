import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WelcomeSplashService } from 'src/app/services/welcome-splash.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  constructor(
    public welcomeSplash: WelcomeSplashService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  navToHome = () : void => {
    this.router.navigate(['/home'])
    this.welcomeSplash.hide()
  }

}
