<!-- --- DESKTOP --- -->

<div class="content-container desktop">
  <div class="flex justify-center align-center">
    <img class="brand-img" src="../../../assets/aboutImage.png" alt="About Image">
  </div>

  <div class="content-area">
    <h2>ABOUT</h2>
    <div class="m-t-1h content-wrapper">
      <p><span class="brand-color bold">Keiken Studio</span> was founded with the ethos that custom design & technology should be accessible, affordable & fair.</p>
      <p>The word Keiken is Japanese in origin and translates to <span class="bold brand-color">"Experience - an encounter that leaves an impression".</span></p>
      <p>Creating meaningful experiences for our clients and their customers is the lifeblood of our Studio.</p>
      <p>Keiken is a boutique studio with a passion for our community, partnering with up and coming business's in our region to empower thriving success.</p>
      <button routerLink="/works" class="m-t-1h">SEE WORKS</button>
    </div>
  </div>
</div>

<!-- --- END DESTOP --- -->


<!-- --- MOBILE --- -->
<div class="content-container mobile">
  <div class="flex justify-end align-center">
    <img class="brand-img" src="../../../assets/aboutImage.png" alt="About Image">
  </div>

  <div class="p-l-1h">
    <h2>ABOUT</h2>
  </div>

  <div class="about-content">
    <p><span class="bold brand-color">Keiken Studio</span> was founded with the ethos that custom design & technology should be accessible, affordable & fair.</p>
    <p>The word Keiken is Japanese in origin and translates to <span class="bold brand-color">"Experience - an encounter that leaves an impression".</span></p>
    <p>Creating meaningful experiences for our clients and their customers is the lifeblood of our studio.</p>
    <p>Keiken is a boutique studio with a passion for our community, partnering with up and coming business's in our region to empower thriving success.</p>
    <button class="m-y-1h" routerLink="/works">SEE WORKS</button>
  </div>
</div>
<!-- --- MOBILE END --- -->