import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ContactSplashService } from './services/contact-splash.service';
import { WelcomeSplashService } from './services/welcome-splash.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  isMobile: boolean = false

  constructor(
    private welcomeSplash: WelcomeSplashService,
    public contactSplash: ContactSplashService,
  ) {

    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.isMobile = true
    }
    console.log(navigator.userAgent)

    if (this.isMobile) {
      this.welcomeSplash.show()
    }
  }

}
