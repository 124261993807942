import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public services = [
    { imgPath: 'icons/services/strategy.png', imgAlt: 'Strategy Icon', heading: 'STRATEGY' },
    { imgPath: 'icons/services/brandidentity.png', imgAlt: 'Brand Identity Icon', heading: 'BRAND IDENTITY' },
    { imgPath: 'icons/services/webenvs.png', imgAlt: 'Web Environments Icon', heading: 'WEB ENVIRONMENTS' },
    { imgPath: 'icons/services/socialmedia.png', imgAlt: 'Socials Icon', heading: 'SOCIAL MEDIA', imgClass: 'm-l-h'},
    { imgPath: 'icons/services/marketing.png', imgAlt: 'Marketing Icon', heading: 'MARKETING' },
  ]
  
  constructor() { }

  ngOnInit(): void {
    
  }

}
