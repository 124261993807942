
<div class="m-x-1 flex align-center">
  <k-i-toggle [control]="optionSelected" class="m-r-1h"></k-i-toggle>
  <h3>{{ heading }}</h3>
</div>

<div class="options-container" *ngIf="optionSelected.value">

  <div class="flex align-center justify-space-between" *ngFor="let o of options">
    <k-i-checkbox [control]="o.formControl" [title]="o.title"></k-i-checkbox>
    <p [ngClass]="o.formControl.value ? '' : 'disabled'">${{ o.price }}</p>
  </div>
  
</div>