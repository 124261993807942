<!-- DESKTOP -->
<div class="content-container desktop">

  <div class="quote-builder-wrapper">
    <app-quote-builder></app-quote-builder>
  </div>

  <div class="content-area">
    <h2>QUOTE</h2>
    <div class="m-t-1h content-wrapper">
      <h3 class="brand-color">Create a customised quote instantly!</h3>
      <p>Fair and transparent pricing is what Keiken is all about.<br />
        Select any combination of our services below to build a<br />
        custom quote that fits your business or big idea.</p>

      <div class="p-l-1h">
        <app-img-w-heading *ngFor="let s of services" [imgPath]="s.imgPath" [imgAlt]="s.imgAlt" [heading]="s.heading"></app-img-w-heading>
      </div>
    </div>
  </div>

</div>


<!-- END DESKTOP -->

<!-- --- MOBILE --- -->
<div class="content-container mobile">
  <div>
    <h2>QUOTE</h2>
  </div>

  <div class="m-b-1 flex justify-center align-center">
    <img class="brand-img" src="../../../assets/gutterpro mockup 1.png" alt="Quote Builder Desktop">
  </div>

  <div>
    <p>Fair and transparent pricing is what Keiken is all about. Access the Keiken <span class="brand-color">Instant
        Quote Builder</span> by opening this page on any desktop device.</p>
    <p>Contact us below to chat about anything business!</p>
    <button class="m-y-1h" (click)="contactSplash.show()">CONTACT</button>
  </div>
</div>