import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-quote-builder-strategy',
  templateUrl: './strategy.component.html',
  styleUrls: ['./strategy.component.css']
})
export class StrategyComponent implements OnInit {

  options: [{ title: string, options: [{ title: string, price: string, formControl: FormControl }] }] = [
    {
      title: 'STRATEGY KICKOFF MEETING',
      options: [
        { title: 'This title', price: '100', formControl: new FormControl(false) }
      ]
    }
  ]

  constructor() {}

  ngOnInit(): void {
  }

}
