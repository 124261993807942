<!-- <div (click)="contactSplash.hide()" style="position:absolute; top:0;left:0; width: 100vw; height: 100vh;"> -->

<div class="overlay" [ngClass]="deviceService.isMobile() ? 'content-pane' : ''" [formGroup]="form" [ngStyle]="!contactSplash.display() ? { 'display': 'none' } : {}">
  <!-- <form > -->
    
    <div [ngClass]="deviceService.isMobile() ? 'content-scroll' : ''">

      <img class="m-y-1h back-img" src="../../../assets/welcome-arrow.png" alt="Welcome arrow" (click)="closePage()">

      <div class="content-wrapper">
        <h2 class="m-b-h">IT ALL STARTS HERE!</h2>
        <h1 class="m-b-1h">CONTACT US FOR A {{ itemText }}<span class="brand-color">.</span></h1>
  
        <mat-form-field class="text-input">
          <mat-label>Name*</mat-label>
          <input formControlName="name" matInput type="text">
        </mat-form-field>
  
        <mat-form-field class="text-input">
          <mat-label>Phone*</mat-label>
          <input formControlName="phone" matInput type="text">
        </mat-form-field>
  
        <mat-form-field class="text-input">
          <mat-label>Email*</mat-label>
          <input formControlName="email" matInput type="text">
        </mat-form-field>
  
        <mat-form-field class="text-input m-b-1">
          <mat-label>Enquiry</mat-label>
          <input formControlName="enquiry" matInput type="text">
        </mat-form-field>
  
        <mat-form-field class="text-area-input m-b-1h">
          <mat-label>Message*</mat-label>
          <textarea formControlName="message" matInput style="height: 60px;"></textarea>
        </mat-form-field>
  
        <button (click)="submitForm()" class="m-b-1h">SUBMIT</button>
  
        <p *ngIf="formMessage.message">{{ formMessage.message }}</p>
      </div>

    </div>

  <!-- </form> -->
</div>
<!-- </div> -->