import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'k-i-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit {
  @Input() title: string
  @Input() disabled: boolean = false

  @Input() control: FormControl

  constructor() { }

  ngOnInit(): void {
    if (this.disabled) { this.control.disable() }
  }

}
