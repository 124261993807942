import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { WorksComponent } from './pages/works/works.component';
import { QuoteComponent } from './pages/quote/quote.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactComponent } from './pages/contact/contact.component';
import { ContactSplashService } from './services/contact-splash.service';
import { WelcomeSplashService } from './services/welcome-splash.service';

import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ImgWHeadingComponent } from './components/img-w-heading/img-w-heading.component';
import { QuoteBuilderComponent } from './pages/quote/quote-builder/quote-builder.component';
import { StrategyComponent } from './pages/quote/quote-builder/strategy/strategy.component';
import { ToggleComponent } from './inputs/toggle/toggle.component';
import { CheckboxComponent } from './inputs/checkbox/checkbox.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PackageSelectionComponent } from './components/package-selection/package-selection.component';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    WorksComponent,
    QuoteComponent,
    WelcomeComponent,
    ContactComponent,
    ImgWHeadingComponent,
    QuoteBuilderComponent,
    StrategyComponent,
    ToggleComponent,
    CheckboxComponent,
    PackageSelectionComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule, 
    HttpClientModule,
    AppRoutingModule,
    MatInputModule,
    MatCheckboxModule,
  ],
  providers: [
    WelcomeSplashService,
    ContactSplashService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
