import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactSplashService {

  private displayContact : boolean = false;

  constructor() { }

  public show = () : void => {
    console.log('show')
    this.displayContact = true;
  }
  public hide = () : void => {
    console.log('hide')
    this.displayContact = false;
  }
  public display = () : boolean => {
    return this.displayContact;
  }
}
