import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { FormBuilder, FormGroup } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ContactSplashService } from 'src/app/services/contact-splash.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  public itemText: string = ''
  public formMessage: any = { message: '' }

  public form : FormGroup

  private items: string[] = [
    '... COFFEE',
    '... CHAT',
    '... QUOTE',
  ]

  constructor(
    public contactSplash: ContactSplashService,
    public deviceService: DeviceDetectorService,
    public builder: FormBuilder,
    public http: HttpClient,
  ) { 
    this.form = this.buildForm()
    this.form.valueChanges.subscribe(_ => {
      console.log(this.form.value)
    })
  }

  async ngOnInit(): Promise<void> {
    while(true) {
      for(let item of this.items) {
        for(let l of item) {
          this.itemText = await this.letter.write(this.itemText, l)
        }
        await this.letter.pause()
        for(let l of item) {
          this.itemText = await this.letter.remove(this.itemText)
        }
      }
    }
  }

  submitForm = async () : Promise<void> => {
    let request = {
      to: ['keikenstudio@gmail.com'],
      cc: [],
      bcc: ['luke.mcwha@gmail.com'],
      subject: 'Keiken Enquiry!',
      attachments: [],
    }

    this.http.post('https://anarpm60ri.execute-api.ap-southeast-2.amazonaws.com/production/api/keiken/lead/send', { ...request, ...this.form.value })
      .subscribe((data) => {
        this.formMessage.message = "Thanks for your enquiry. We'll be in touch shortly! ^_^"
        console.log(data)
        this.form.reset()
      })
  }

  closePage = () : void => {
    this.contactSplash.hide()
  }

  letter = {
    write: (text, letter) : Promise<string> => {
      return new Promise(resolve => {
        setTimeout(() => {
          return resolve(text += letter)
        }, 160)
      })
    },
    remove: (text) : Promise<string> => {
      return new Promise(resolve => {
        setTimeout(() => {
          return resolve(text.slice(0, -1))
        }, 100)
      })
    },
    pause: () : Promise<void> => {
      return new Promise(resolve => {
        setTimeout(() => {
          return resolve()
        }, 1200)
      })
    }
  }

  buildForm = () : FormGroup => {
    return this.builder.group({
      name: [''],
      email: [''],
      phone: [''],
      enquiry: [''],
      message: ['']
    })
  }
}
