import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-img-w-heading',
  templateUrl: './img-w-heading.component.html',
  styleUrls: ['./img-w-heading.component.css']
})
export class ImgWHeadingComponent implements OnInit {

  private assetsPath = '../../../assets'

  @Input() imgPath: string = ''
  @Input() imgAlt: string = ''
  @Input() heading: string = ''
  @Input() imgClass: string = ''
  
  constructor() {}

  ngOnInit(): void {
    this.imgPath = `${this.assetsPath}/${this.imgPath}`
  }

}
