<!-- DESKTOP -->

<div class="content-container desktop">

  <!-- <div>

  </div> -->

  <div class="carousel-img-grid">


    <div class="flex align-center justify-center img-container" [@simpleFadeAnimation]="animationState">
      <img class="m-b-1 brand-img" [src]="selectedCarousel.imgSrc" [ngClass]="selectedCarousel.class ? selectedCarousel.class : ''" alt="Brand display">
    </div>
    
    <div class="flex align-center justify-center">
      <div *ngFor="let slide of carouselData; let i = index" class="m-r-1 slide-toggles clicky" [ngClass]="i == selectedIndex ? 'slide-selected' : ''" (click)="selectedChange(i)"></div>
    </div>
  </div>

  <div class="content-area">
    <h2>WORKS</h2>
    <div class="m-t-1h m-l-1h content-wrapper" [@simpleFadeAnimation]="animationState">
      <h3 class="brand-color bold">{{ selectedCarousel.title }}</h3>
      <p *ngFor="let text of selectedCarousel.description">{{ text }}</p>

      <div class="quote-wrapper m-y-1h flex align-center">
        <img class="m-r-1" [src]="selectedCarousel.thumbnailSrc" alt="Thumbnail">
        <p>{{ selectedCarousel.quoteText }} <span class="brand-color bold">- {{ selectedCarousel.quoteWriter }}</span></p>
      </div>

      <button routerLink="/quote">GET A QUOTE</button>
    </div>
  </div>
</div>

<!-- END DESKTOP -->


<!-- --- MOBILE --- -->
<div class="content-container mobile">
  <div class="heading-grid p-x-h">
    <div class="flex align-center justify-center">
      <h2>WORKS</h2>
    </div>
    
    <div class="flex align-center justify-end">
      <!-- <button routerLink="/quote">GET A QUOTE</button> -->
      <button (click)="contactSplash.show()">GET A QUOTE</button>
    </div>
  </div>

  <div class="p-l-1h p-r-1h"  [@simpleFadeAnimation]="animationState">
    <div class="carousel-img-grid">
      <div class="flex align-center justify-center m-y-1 ">
        <img class="brand-img" [src]="selectedCarousel.imgSrc" alt="Brand display">
      </div>
      
      <div class="flex align-center justify-center m-b-1h">
        <div *ngFor="let slide of carouselData; let i = index" class="m-r-1 slide-toggles clicky" [ngClass]="i == selectedIndex ? 'slide-selected' : ''" (click)="selectedChange(i)"></div>
      </div>
    </div>

    <h3 class="brand-color bold">{{ selectedCarousel.title }}</h3>
    <p>{{ selectedCarousel.description }}</p>
    <div class="m-y-1h flex align-center">
      <img class="m-r-1 slide-thumbnail" [src]="selectedCarousel.thumbnailSrc" alt="Thumbnail">
      <p>{{ selectedCarousel.quoteText }} <span class="brand-color bold">- {{ selectedCarousel.quoteWriter }}</span></p>
    </div>
  </div>
</div>
<!-- --- MOBILE END --- -->