import { Component, OnInit } from '@angular/core';
import { ContactSplashService } from 'src/app/services/contact-splash.service';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css']
})
export class QuoteComponent implements OnInit {

  public services = [
    { imgPath: 'icons/services/strategy.png', imgAlt: 'Strategy Icon', heading: 'STRATEGY' },
    { imgPath: 'icons/services/brandidentity.png', imgAlt: 'Brand Identity Icon', heading: 'BRAND IDENTITY' },
    { imgPath: 'icons/services/webenvs.png', imgAlt: 'Web Environments Icon', heading: 'WEB ENVIRONMENTS' },
    { imgPath: 'icons/services/socialmedia.png', imgAlt: 'Socials Icon', heading: 'SOCIAL MEDIA' },
    { imgPath: 'icons/services/marketing.png', imgAlt: 'Marketing Icon', heading: 'MARKETING' },
  ]

  constructor(
    public contactSplash: ContactSplashService
  ) { }

  ngOnInit(): void {
  }

}
